<script setup>
    import {DAILY_REFUND_SOURCE} from '@jetCommon/constants/daily-refund-constants.js';

    import JetLink from '@jetDS/components/JetLink.vue';

    const props = defineProps({
        source: {
            type: String,
            required: true,
        },
    });

    const dailyRefundInfo = computed(() => {
        if ([DAILY_REFUND_SOURCE.TRIP_REGION, DAILY_REFUND_SOURCE.TRIP_ITALY].includes(props.source)) {
            return {
                location: 'in Italia',
                limit: '46,48',
                without_board_or_lodging_limit: '30,99',
                without_board_and_lodging_limit: '15,49',
            };
        }
        return {
            location: "all'estero",
            limit: '77,47',
            without_board_or_lodging_limit: '51,65',
            without_board_and_lodging_limit: '25,82',
        };
    });
</script>

<template>
    <div>
        Per le trasferte {{ dailyRefundInfo.location }}, il limite esente è di {{ dailyRefundInfo.limit }} €. Se viene
        rimborsato a parte il vitto o l'alloggio, il limite si riduce a
        {{ dailyRefundInfo.without_board_or_lodging_limit }} €, mentre se entrambi sono rimborsati, si riduce a
        {{ dailyRefundInfo.without_board_and_lodging_limit }} €.
        <br />
        <JetLink
            class="TripDailyRefundInfoAndLink_Link"
            href="https://help.jethr.com/it/articles/9019589-trasferta-come-funziona-il-rimborso-spese"
            target="blank">
            Leggi l'articolo
        </JetLink>
    </div>
</template>

<style lang="scss" scoped>
    .TripDailyRefundInfoAndLink_Link {
        color: var(--jet-color-primary);
        font-weight: var(--jet-font-weight-bold);
        font-size: var(--jet-font-size-xs);
    }
</style>
